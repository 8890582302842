.dev-icons {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin-bottom: 0px;
  margin-top: 0px;
}

.software-skill-inline {
  display: inline-flex;
  margin-right: 20px;
  margin-bottom: 20px;
  font-family: "Google Sans Regular";
  gap: 4px;
  flex-flow: column;

}

.software-skill-inline > i {
  color: #868e96;
}

.software-skill-inline > i:hover {
  color: #645beb;
}

.software-skill-inline span { 
   font-size: 20px;
}

.iconify {
   align-self: center;
}

.skillname {
   align-self: center;
   font-size: 20px;
}

.tile-card-div {
  color: rgb(88, 96, 105);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
  padding: 2rem;
  cursor: pointer;
  flex: 1 1 25%;
}
.tile-card-div:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
}

.tile-name-div {
  display: flex;
  align-items: left;
  display: flex; /* dit is de truuc om de div van card-img ernaast te krijgen) */
  justify-content: space-between; /*met flex space er tussen */
  align-items: center;
}

.tile-name {
  text-align: left;
  color: rgb(36, 41, 46);
  font-family: "Google Sans Regular";
  margin-bottom: 0.75rem;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: -0.5px;
  overflow: hidden;
  line-height: 1.2;
  margin: 0px;
}

.tile-description {
  overflow: hidden;
  text-align: justify;
  display: flex;
  font-family: "Google Sans Regular";
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 0.8rem;
}

.card-img {
  min-width: 200px;
  display: inline-flex;
  padding: 20px;
}
.tile-details {
  display: flex;
  flex-direction: row;
}

.tile-creation-date {
  margin: 0;
  font-family: "Google Sans Regular";
  font-weight: 400;
  margin-left: 0px;
  margin-right: auto;
}

/* .wrapper {
overflow: hidden;
width: 800px;
    border: 1px solid black;
}

.wrapper2 {
    float:left;
    border: 1px solid red;
} 
*/



@media (max-width: 1200px) {
  .tile-card-div {
    flex: 1 1 40%;
  }
}

@media (max-width: 768px) {
  .tile-card-div {
    flex: 1 1 100%;
  }

  .tile-name {
    font-size: 16px;
  }

  .tile-description {
    font-size: 14px;
  }

  .tile-details {
    flex-direction: column;
  }

  .tile-creation-date {
    font-size: 14px;
  }
}
